import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Navbar from "./compoments/Navbar";
import Footer from "./compoments/Footer";
import ServficePage from "./pages/ServficePage";
import Settings from "./pages/Settings";
import PageforTest from "./pages/PageforTest";
import Login from "./pages/Login";
const App = () => {
  
  return (
    <div className="App">
      <Navbar />
    <BrowserRouter>
           <Routes>
                 <Route path="/" element={<Home />} />    
                 {/* <Route path="/services/:id/" element={<ServficePage />} />    
                 <Route path="/login" element={<Login />} />     */}
            </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;