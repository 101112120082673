import React, { useState , useEffect } from 'react'
import Settings from './Settings'
import axios from 'axios'
function Login() {
    const [name , setName ] = useState()
    const [password , setPassword ] = useState()
    const [erroMsg , seterroMsg ] = useState('')
    const [code , setcode ] = useState('')
    const [subPage , setsubPage] = useState('login')
    const [seePassword , setSeePassword] = useState(true)
    const [changePassword , setchangePassword] = useState(true)
    const [changePassword1 , setchangePassword1] = useState(true)
    const [lg  , setLg ] = useState(false)




    const checkingEmail = async ()=> {
      const DataForm= new FormData();
      if (name == '' && password == '' ){
        seterroMsg('Assurez-vous de remplir l input')
      } else {
      if (/\S+@\S+\.\S+/.test(name)){
        
        DataForm.append('name' , name)
        DataForm.append('password' , password)
        await axios ({
          method : 'post' , 
          url : 'http://127.0.0.1:8000/login/' ,
          data : DataForm
      })
      .then((response)=>{
          // console.log(response.data) ;
          if (response.data == 1){
            setLg(true)
          }else {
            setLg(false)
            seterroMsg('l\'email ou le mot de passe ne sont pas corrects')
          }
      }) .catch(function (error) {
          console.log(error)
        });


      }else {
        seterroMsg('email not right')
      }
    }
    }

    const checkCode = async ()=> {
      const DataForm= new FormData();
      if (code == ''){
        seterroMsg('Assurez-vous de remplir l input')
      } else {
        
        DataForm.append('code',code)
      await axios ({
        method : 'post' , 
        url : 'http://127.0.0.1:8000/login/chekingcode/' ,
        data : DataForm
    })
    .then((response)=>{
        console.log(response.data) ;
        if (response.data == 1 ){
          setsubPage('changPassword')
          seterroMsg('')
        }else {
          seterroMsg('Code incorrect')
        }
       
    }) .catch(function (error) {
        console.log(error)
      });
    }
  }

  const changingPassword = async ()=> {
   
    const DataForm= new FormData();

    if (changePassword == '' && changePassword1 == ''){
      seterroMsg('Assurez-vous de remplir l input')
    } else {
      if ( changePassword.localeCompare(changePassword1) != 0 ){
        seterroMsg('Les deux champs de mot de passe ne correspondaient pas')
      } else {
      DataForm.append('name',name)
      DataForm.append('password',changePassword)
    await axios ({
      method : 'post' , 
      url : 'http://127.0.0.1:8000/login/changingpassword/' ,
      data : DataForm
  })
  .then((response)=>{
      console.log(response.data) ;
      
      setsubPage('login')
     
  }) .catch(function (error) {
      console.log(error)
    });
  }
  }
}

    const CreatCode = async ()=> {
   
      const DataForm= new FormData();
      if (name == ''){
        seterroMsg('veuillez entrer un nom d\'utilisateur')
      } else {
      setsubPage('forgetPassword')
      seterroMsg('')
      await axios ({
        method : 'post' , 
        url : 'http://127.0.0.1:8000/login/sendingcode/' ,
        data : DataForm
    })
    .then((response)=>{
        console.log(response.data) ;
       
    }) .catch(function (error) {
        console.log(error)
      });
    }
    }
   

    var logItems = <div></div> 
    switch (subPage) {
      case 'login':
        logItems =  <div className="Login center">
                        <div className='container rad20 center'>
                            <h3>Login</h3>
                        <input type='text' autoComplete="off"  className='rad20' onChange={(e)=> setName(e.target.value) }  placeholder='email' />
                        <input autoComplete="off"  type={seePassword ? 'password' : 'text' } className='rad20' onChange={(e)=> setPassword(e.target.value) } placeholder='mot de pass'  onFocus={(event) => { event.target.setAttribute('autocomplete', 'off');   }} />
                        <div className='errorMsg center' > {erroMsg} </div>
                        <div className='forget ' onClick={()=>CreatCode()} >oublier mot de pass ?</div>
                        <div className='forget ' onClick={()=>setSeePassword(!seePassword)} >{seePassword ? 'voir le mot de passe ?' : 'masquer le mot de passe ?' }</div>
                        <button className='rad20' onClick={()=>checkingEmail()} >Login</button>
                        </div>
                    </div>
        break;
        case 'forgetPassword':
          logItems =  <div className="Login center">
                        <div className='container rad20 center'>
                          <p>Nous vous envoyons le code à votre e-mail t******f@****m </p>
                          <input type='text' className='code' onChange={(e)=>setcode(e.target.value)  } />
                          <div className='errorMsg center' > {erroMsg} </div>
                          <button className='check' onClick={()=>checkCode()} >vérifier</button>
                        </div>
                      </div>
          break ;
        case 'changPassword' :
          logItems = <div className="Login center">
                        <div className='container rad20 center'>
                        <h3>Changer mot de pass</h3>
                             <input type={seePassword ? 'password' : 'text' }  className='code rad20' onChange={(e)=>setchangePassword(e.target.value)  } />
                             <input type={seePassword ? 'password' : 'text' }   className='code rad20' onChange={(e)=>setchangePassword1(e.target.value)  } />
                             <div className='errorMsg center' > {erroMsg} </div>
                             <div className='forget ' onClick={()=>setSeePassword(!seePassword)} >{seePassword ? 'voir le mot de passe ?' : 'masquer le mot de passe ?' }</div>
                             <button className='check rad20' onClick={()=>changingPassword()} >Enregistrer</button>
                        </div>
                      </div>
      default:
        break;
    }
   

  return ( 
  <div>
   {  lg ? <Settings />  : logItems }
   </div>
  )
}

export default Login