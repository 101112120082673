import React , {useEffect , useState} from 'react'
import big_logo_confidence_partner from '../img/big_logo_confidence_partner.png'
import linkdin from '../icons/linkdin.svg'
import insta from '../icons/insta.svg'
import twiter from '../icons/twiter.svg'
import facebook from '../icons/facebook.svg'
import Telegrame from '../icons/Telegrame.svg'
function Footer() {

    
  const [data , setData ] = useState([])
  const [liens , getLiens ] = useState([])
  const [phonenumb , setPhone ] = useState('+212 644-316520')
  const [address , setaddress ] = useState('')
  const [mail , setmail ] = useState('contact@confidencepartner.ma')



  const [instagram , satinstagram] = useState('')
    const [telegram , sattelegram] = useState('')
    const [twit , sattwiter] = useState('')
    const [faceb , satfacebook] = useState('')
    const [linkding , satlinkding] = useState('')


//     useEffect(()=>{
//         getData() ;

//     },[])
//     useEffect(()=>{
       
//         getlinks() ;
      
//     },[])
//     useEffect(()=>{
       
//         getServ() ;
//     },[])

//     let getData = async () => {
//         let respons = await fetch ('http://127.0.0.1:8000/general/getInfo')
//         let data = await respons.json()
//         setData(data)
//         setPhone(data[0]['Phonenumber'])
//         setaddress(data[0]['address'])
//         setmail(data[0]['mail'])
//     }

//     let getlinks = async () => {
//       let respons = await fetch ('http://127.0.0.1:8000/general/getILinks')
//       let data = await respons.json()
//       setData(data)
//     //   console.log(data)
//       satlinkding(data['linkding'])
//       satfacebook(data['facebook'])
//       sattwiter(data['twiter'])
//       sattelegram(data['telegram'])
//       satinstagram(data['instagram'])
   
//   }

//   const [serv , setServ ] = useState()

//   let getServ = async () => {
//     let respons = await fetch ('http://127.0.0.1:8000/service/all/')
//     let data = await respons.json()
//     setServ(data)
 
// }
    // let item = <div></div>
// if (serv != undefined ) {
//     item = serv.map((ob , i )=> 
//         <a href='#' key={i}><li> {ob.name} </li></a>
//        )
     
// }
let item = <>
       <li>Formation Qualité, Sécurité  et Environnement </li>
       <li>Accompagnement QSE</li>
       <li>Accompagnement Démarche de changement culturel Sécurité au Travail </li>
       <li>Audit Interne et Audit Niveau 2 </li>
       <li>Etudes Sécurité et Environnement </li>
      </>


  return (
    <footer className='center' >
        
            <div className='top spacearound' >
                <img className='logo' src={big_logo_confidence_partner} />
                <div className='links'>
                    <a href={linkding} target="_blank" ><img src={linkdin} className='socialIcon' /></a>
                    <a href={instagram} target="_blank" > <img src={insta} className='socialIcon' /> </a>
                    <a href={twit} target="_blank" ><img src={twiter} className='socialIcon' /></a>
                    <a href={faceb} target="_blank" > <img src={facebook} className='socialIcon' /></a>
                    <a href={telegram} target="_blank" > <img src={Telegrame} className='socialIcon' /></a>
                </div>
            </div>

            <nav className='center'>
                <ul className='center'>
                    <h2>Nos services</h2>
                  {item}
                    {/* <a href='#'><li>Formation Qualité, Sécurité  et Environnement</li></a>
                    <a href='#'><li>Formation Qualité, Sécurité  et Environnement</li></a>
                    <a href='#'><li>Formation Qualité, Sécurité  et Environnement</li></a>
                    <a href='#'><li>Formation Qualité, Sécurité  et Environnement</li></a>
                    <a href='#'><li>Formation Qualité, Sécurité  et Environnement</li></a> */}
                </ul >
                <ul className='center'>
                    <h2>Nos Contact</h2>  
                    <a href='#'><li>{phonenumb}</li></a>
                    <a href='#'><li>{mail}</li></a>
                </ul>
              
        </nav>
    </footer>
  )
}

export default Footer