import React from 'react'
import courtier_en_travaux from '../../img/courtier_en_travaux.png'
import arrowLeft from '../../icons/arrowLeft.svg'
import {Link} from "react-router-dom";
import {useParams} from "react-router-dom"


function ServPetitSection({id , ob , img , name , desc }) {

  return (
    <div className='ServPetitSection rad10' >
        <img  src={img} className='rad10 backgImage' />
        <h4 className='title'>{ name }</h4>
        <p> {desc} ... </p>
        <div   className='forPlus center'>
        {/* <a 
        href={`http://localhost:3000/services/${ob.id}`
        } target="_blank">Savoir pllus <img src={arrowLeft}/></a> */}
        </div>
    </div>
  )
}

export default ServPetitSection