import React , {useState , useEffect} from 'react'
import letterIcon from '../icons/letterIcon.svg'
// import axios from 'axios'
function Contantact() {

  const [email , setEmail ] = useState ('')
  const [sujet , setSujet ] = useState ('')
  const [name , setName ] = useState ('')
  const [msg , setMsg ] = useState ('')
  const [ hedermsng  , setHeaderMsg] = useState('')

  const sendingEmail = async ()=> {
  //   if ( name == '' &&  sujet == '' && email == ''  && msg == '' ){
  //     setHeaderMsg('assurez-vous que tous les champs ne sont pas vides')
  // }
  // else {
  //   const DataForm= new FormData();
  //   DataForm.append('email' , email)
  //   DataForm.append('sujet' , sujet)
  //   DataForm.append('name' , name)
  //   DataForm.append('msg' , msg)

  // await axios ({
  //     method : 'POST' , 
  //     url : 'http://127.0.0.1:8000/sendingemail/' ,
  //     data : DataForm
  // })
  // .then((response)=>{
  //     console.log(response.data) ;
  // }) .catch(function (error) {
  //     console.log(error)
  //   });
   
  //   setHeaderMsg('Enregistré avec succès')
  //   setMsg('')
  //   setName('')
  //   setEmail('')
  //   setSujet('')
  // }
  }
  return (
    <div className='Contantact center'>
        <div className='icon center'>
            <img src={letterIcon} />
        </div>
        <div className='emailing center'>
            <h4>Contactez-nous</h4>
            <input  type="text" name='Nome' placeholder='Nome' id='Nome' className='rad20' onChange={(e)=>  setName(e.target.value) }  value={name} />
            <input  type="text" name='email' placeholder='email' id='email'  className='rad20' onChange={(e)=>  setEmail(e.target.value) } value={email} />
            <input  type="text" name='Sujet' placeholder='Sujet' id='Sujet'  className='rad20' onChange={(e)=>  setSujet(e.target.value) }  value={sujet} />
            <textarea  type="text" name='Message' placeholder='Message' id='Message' rows="4" cols="50"  className='rad20' onChange={(e)=>  setMsg(e.target.value) } value={msg}  />
            <div className='responseMsg'> {hedermsng} </div>
            <button className='rad20' onClick={()=> sendingEmail() } >Evoyer l’email</button>
        </div>
    </div>
  )
}

export default Contantact