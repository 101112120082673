import React , {useState , useEffect} from 'react'
import big_logo_confidence_partner from '../../img/big_logo_confidence_partner.png'
 
function MoreAbout() {
  //   const [text , settext ] = useState() 
  //   useEffect(()=>{
  //     getData() ;
  // },[])
  
  // let getData = async () => {
  //     let respons = await fetch ('http://127.0.0.1:8000/webcompo/')
  //     let data = await respons.json()
  //     data.map((ob,i)=>{
  //       settext(ob['desctiption'])
  //     })
  // }
  return (
    <div className='ServiceAbout'>
        <h4>Apropos de <span>  nous </span> :</h4>
        <div className='about spacebetween'>
            <div className='desc '>
                <p> Confidence Partner est une entreprise dédiée à votre succès et à votre épanouissement. En tant que partenaire de confiance, nous offrons une gamme de services et de solutions personnalisées pour vous guider vers l'excellence. Que ce soit à travers du coaching individuel, des ateliers de renforcement de la confiance en soi, des formations en qualité, sécurité et environnement, ou encore de l'accompagnement dans le changement culturel, nous sommes là pour vous aider à atteindre vos objectifs les plus ambitieux. Avec notre expertise, notre soutien solide et notre approche axée sur la qualité et le bien-être, nous sommes déterminés à construire une relation de confiance et à vous accompagner sur le chemin de la réussite </p>
                </div>
                <div className='icon center' >
                    <img src={big_logo_confidence_partner} />
                </div>
        </div>
    </div>
  )
}

export default MoreAbout