import React , {useState , useEffect } from 'react'
import ServPetitSection from './smallCompo/ServPetitSection'
import Formation  from '../img/Formation.jpg'
import qhse_et_qse  from '../img/qhse_et_qse.jpg'
import safety  from '../img/safety.jpg'
function ServicesBoxes() {

//   const [data , setData ] = useState([])

//   useEffect(()=>{
//     getData() ;
// },[])

// let getData = async () => {
//     let respons = await fetch ('http://127.0.0.1:8000/service/all/')
//     let data = await respons.json()
//     setData(data)
//   //  console.log(data)
// }




  return (
    <div className='ServicesBoxes'>
        <h3>Nos services</h3>
        <div className='container center'>
        {/* {
          data.map((ob , i ) =>
          <ServPetitSection id={i} ob={ob} key={i} />
          )
        } */}
       
        <ServPetitSection 
        name = 'Formation Qualité, Sécurité  et Environnement'
        desc = "Acquérez les compétences nécessaires pour assurer la conformité aux normes et réglementations. Améliorez la gestion de la qualité, la sécurité au travail et la protection de l'environnement. Contribuez à un environnement de travail sûr et durable"
        img = {Formation}
       />
         <ServPetitSection 
        name = 'Accompagnement QSE'
        desc = "Soutien expert pour améliorer votre système de management QSE. Stratégies efficaces, conformité réglementaire et culture QSE positive. Visons l'excellence opérationnelle et la durabilité de votre organisation."
        img = {qhse_et_qse}
       />
         <ServPetitSection 
        name = 'Accompagnement Démarche de changement culturel Sécurité au Travail'
        desc = "Cultivons une culture de sécurité solide. Sensibilisation, formation et comportements sécuritaires. Créons un environnement de travail sûr et prévenant"
        img = {safety}
       />
         <ServPetitSection 
        name = 'Audit Interne et Audit Niveau 2'
        desc = " Évaluez la conformité et l'efficacité de votre système de gestion. Nos experts mènent des audits rigoureux pour identifier les forces et les opportunités d'amélioration. Obtenez des recommandations précieuses pour renforcer votre performance opérationnelle"
        img = {"https://images.shiksha.com/mediadata/shikshaOnline/mailers/2021/naukri-learning/oct/28oct/What-is-AUDIT.jpg"}
       />
         <ServPetitSection 
        name = 'Etudes Sécurité et Environnement'
        desc = " Analysons les risques et les impacts pour votre organisation. Nos études approfondies évaluent les mesures de sécurité et les pratiques environnementales. Obtenez des recommandations personnalisées pour une gestion optimale de la sécurité et de l'environnement"
        img = {"https://www.g4s.com/fr-lu/-/media/g4s/luxembourg/images/modules/newsandcontent/pages/conseils-et-formation-en-securite/etudes_780x440.ashx"}
       />
       
        </div>
    </div>
  )
}

export default ServicesBoxes