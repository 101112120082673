import React , {useState , useEffect} from 'react'
import crouzet_logo_vector_removebg_preview from '../img/crouzet_logo_vector_removebg_preview.png'
import Majorel_Logo_2019 from '../img/Majorel_Logo_2019.png'
import ocp_solution from '../img/ocp_solution.png'
function Partners() {

    // const [data , setData ] = useState([])
    // useEffect(()=>{
    //     getData() ;
    // },[])
    
    // let getData = async () => {
    //     let respons = await fetch ( 'http://127.0.0.1:8000/setpartner/')
    //     let data = await respons.json()
    //     setData(data)       
    // }



  return (
    <div className='Partners '>
        <h3>Notre partenaire</h3>
        <div className='contain center  '>
           
           {/* { data.map((ob , i ) => 
                <div key={i} className =  ' big center rad10' >
                    <img src={ 'http://127.0.0.1:8000/' + ob.picture} />
                </div>
            )} */}
            <div  className =  ' big center rad10' >
                    <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/OCP_Group.svg/1200px-OCP_Group.svg.png' />
                </div>
                 <div  className =  ' big center rad10' >
                    <img src={ocp_solution} />
                </div>
                <div  className =  ' big center rad10' >
                    <img src={"https://upload.wikimedia.org/wikipedia/commons/e/e5/L%26T.png"} />
                </div>
                <div  className =  ' big center rad10' >
                    <img src={"https://www.forumhorizonsmaroc.com/wp-content/uploads/2022/05/Logo-CMS-HD-300x260.jpg"} />
                </div>
        </div>
        
            
    </div>
  )
}

export default Partners