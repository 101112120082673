import React , {useState,useEffect} from 'react'
import LOGO_confidencePartner from '../img/LOGO_confidencePartner.png'
import gmail from '../icons/gmail.svg'
import gmail_w from '../icons/gmail_w.svg'
import phone from '../icons/phone.svg'
import { Link } from 'react-router-dom';


function Navbar() {


  const [data , setData ] = useState([])
  const [phonenumb , setPhone ] = useState('+212 644-316520')
  const [address , setaddress ] = useState('')
  const [mail , setmail ] = useState('contact@confidencepartner.ma')
    // useEffect(()=>{
    //     getData() ;
    // },[])

    // let getData = async () => {
    //     let respons = await fetch ('http://127.0.0.1:8000/general/getInfo')
    //     let data = await respons.json()
    //     setData(data)

    //     setPhone(data[0]['Phonenumber'])
    //     setaddress(data[0]['address'])
    //     setmail(data[0]['mail'])
    // }



  return (
    <div className='Navbar spacebetween'>
      
    <a href='/'>  <img className='logoImg' src={LOGO_confidencePartner} /> </a>
      <div className='desc center'>
        <div className='box center'>
            <img src={phone} />
            <p>{phonenumb} </p>
        </div>
        <div className='box center'>
          <img src={gmail_w} />
          <p>{mail}</p>
        </div>
      </div>
      <a href='http://localhost:3000/#contact'><button className='rad20'>Contact</button></a>
         
    </div>
  )
}

export default Navbar 