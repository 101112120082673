import React ,{useState,useEffect} from 'react'
import map from '../icons/map.svg'
import linkdin from '../icons/linkdin.svg'
import insta from '../icons/insta.svg'
import twiter from '../icons/twiter.svg'
import facebook from '../icons/facebook.svg'
import Telegrame from '../icons/Telegrame.svg'
function Links() {


  const [data , setData ] = useState([])
  const [liens , getLiens ] = useState([])
  const [phonenumb , setPhone ] = useState('')
  const [address , setaddress ] = useState('')
  const [mail , setmail ] = useState('')



  const [instagram , satinstagram] = useState('')
    const [telegram , sattelegram] = useState('')
    const [twit , sattwiter] = useState('')
    const [faceb , satfacebook] = useState('')
    const [linkding , satlinkding] = useState('')


    // useEffect(()=>{
    //     getData() ;
    //     getlinks() ;
    // },[])

    // let getData = async () => {
    //     let respons = await fetch ('http://127.0.0.1:8000/general/getInfo')
    //     let data = await respons.json()
    //     setData(data)
    //     setPhone(data[0]['Phonenumber'])
    //     setaddress(data[0]['address'])
    //     setmail(data[0]['mail'])
    // }

  //   let getlinks = async () => {
  //     let respons = await fetch ('http://127.0.0.1:8000/general/getILinks')
  //     let data = await respons.json()
  //     setData(data)
  //     // console.log(data)
  //     satlinkding(data['linkding'])
  //     satfacebook(data['facebook'])
  //     sattwiter(data['twiter'])
  //     sattelegram(data['telegram'])
  //     satinstagram(data['instagram'])
   
  // }

  return (
    <div className='Links spacebetween'>
        <div className='map center'>
            <img src={map} />
            <p>{address} </p>
        </div>
        <div className='social center '>
            <a href={linkding} target="_blank" ><img src={linkdin} className='socialIcon' /></a>
            <a href={instagram} target="_blank" > <img src={insta} className='socialIcon' /> </a>
            <a href={twit} target="_blank" ><img src={twiter} className='socialIcon' /></a>
            <a href={faceb} target="_blank" >     <img src={facebook} className='socialIcon' /></a>
            <a href={telegram} target="_blank" >    <img src={Telegrame} className='socialIcon' /></a>

            {/* <img src={linkdin} className='socialIcon' />
           
            
        
          */}
        </div>
    </div>
  )
}

export default Links