import React , {useState,useEffect}from 'react'
import {Link} from "react-router-dom";
import Oaklins_Industry_expertise from '../img/Oaklins_Industry_expertise.jpg'
import hero_img from '../img/hero_img.jpg'
function Header({setmoreAbout}) {

//   const [data , setData ] = useState([])

//   const [title , setTitle ] = useState('')
//   const [desc , setDesc ] = useState('') 
//   const [picture , setPic ] = useState() 
//   useEffect(()=>{
//     getData() ;
// },[])

// let getData = async () => {
//     let respons = await fetch ('http://127.0.0.1:8000/webcompo/')
//     let data = await respons.json()
//     setData(data)
//     data.map((ob,i)=>{
//       setDesc(ob['desctiption'])
//       setTitle(ob['grandTitre'])
//       setPic(ob['picture'])
//     })
// }
  return (
    <div className='Header'>
        <img className='backgroundImage filter06' src={hero_img} />
        <div className='content'>
            <h1> Expert de Confiance : Transformez vos Objectifs en Réalité </h1>
            <p>Partenaire de Confiance : Votre Guide vers la Réussite. Renforcez votre confiance, dépassez vos limites et atteignez vos objectifs les plus ambitieux. Avec nous, tracez une voie vers un avenir brillant et prospérez </p>
            <div className='buttns center'>
                <a className='center rad20 headerRespons ' href='http://localhost:3000/#contact'>Contactez-nous</a>
                <button className='rad20 headerRespons' onClick={()=> setmoreAbout(true)} >Savoir plus</button>

            </div>
        </div>
    </div>
  )
}

export default Header