import React ,{useState} from 'react'
import Header from '../compoments/Header'
import ServicesBoxes from '../compoments/ServicesBoxes'
import Partners from '../compoments/Partners'
import Actulite from '../compoments/Actulite'
import Links from '../compoments/Links'
import Contantact from '../compoments/Contantact'
import MoreAbout from '../compoments/SettingsCompo/MoreAbout'
function Home() {

  const [moreAbout , setmoreAbout ] = useState(false)

  return (
    <div>
      <Header setmoreAbout={setmoreAbout} />
       {moreAbout ?  <MoreAbout /> : <div></div>  } 
      <ServicesBoxes />
      <Partners />
      {/* <Actulite /> */}
      <div id='contact'></div>
      <Links /> 
      <Contantact /> 
    </div>
  )
}

export default Home